<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="점검항목별 개선목록"
        tableId="grid"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :gridHeight="gridheight"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="false"
              :isImmShow="true"
              ibmTaskTypeCd="ITT0000066"
              ibmTaskUnderTypeCd="ITTU000115"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridImpr"
        title="항목외 개선 목록"
        :columns="gridImpr.columns"
        :data="gridImpr.data"
        :gridHeight="gridheight2"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled && popupParam.saiPatrolVendorId" label="LBLIMPRREQUEST" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <!-- <c-btn v-if="editable && !disabled && popupParam.saiPatrolVendorId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" /> -->
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrol-cause-prevention',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolVendorId: '',
        vendorCd: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'patrolItemName',
            field: 'patrolItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolResultName',
            field: 'patrolResultName',
            label: '점검결과',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'patrolContents',
            field: 'patrolContents',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            style: 'width:300px',
            type: 'custom',
            sortable: false
          },
        ],
        
        data: [],
        height: '200px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        patrol: {},
        closeCallback: null,
      },
      standardInfo: {
        saiPatrolVendorId: '',
      },
      patrol: {
        patrolVendorImproveModelList: [],
      },
      colorItems: [],
      gridheight: '',
      gridheight2: '',
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      updateUrl: 'transactionConfig.sai.patrolvendor.improve.update.url',
      deleteImproveUrl: '',
      completeUrl: '',
      impListUrl: '',
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          // {
          //   name: 'actionDeptName',
          //   field: 'actionDeptName',
          //   label: '조치부서',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  computed: {
    disabled() {
      return this.standardInfo.patrolCompleteFlag === "Y";
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'count'() {
      this.getStdInfo();
      this.getDetail();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getInfoUrl = selectConfig.sai.patrolvendor.improve.impr.url;
      this.getUrl = selectConfig.sai.patrolvendor.get.url;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;

      this.gridheight2 = (window.innerHeight - 658) + 'px';
      this.gridheight = '360px';

      // code setting
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      this.getDetail();
      this.getStdInfo();
      this.getImpList();
    },
    getStdInfo() {
      if (this.popupParam.saiPatrolVendorId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.saiPatrolVendorId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = _result.data;
        },);
      }
    },
    getDetail() {
      if (this.popupParam.saiPatrolVendorId) {
        this.$http.url = this.getInfoUrl;
        this.$http.param = {saiPatrolVendorId: this.popupParam.saiPatrolVendorId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    getImpList() {
      if (this.popupParam.saiPatrolVendorId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000066', 
          ibmTaskUnderTypeCd: 'ITTU000110', 
          relationTableKey: this.popupParam.saiPatrolVendorId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    completePlan() {
      this.saveUrl = this.completeUrl;
      this.saveType = 'PUT';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.patrol.regUserId = this.$store.getters.user.userId
              this.patrol.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
      this.getStdInfo();
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiPatrolVendorId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    linkClick(data, row, num) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    linkClick2(row) {
      this.linkClick(row, row, '2');
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    research() {
      this.$emit('research');
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: this.standardInfo.patrolName,
        relationTableKey: this.popupParam.saiPatrolVendorId,
        ibmTaskTypeCd: 'ITT0000066',
        ibmTaskUnderTypeCd: 'ITTU000110', 
        vendorCd: this.popupParam.vendorCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = 'LBLIMMEDIATE';
      this.popupOptions.param = {
        requestContents: this.standardInfo.patrolName,
        relationTableKey: this.popupParam.saiPatrolVendorId,
        ibmTaskTypeCd: 'ITT0000066',
        ibmTaskUnderTypeCd: 'ITTU000110', 
        vendorCd: '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>
